html, body {
    height: 100%;
    background-color: rgba(0,0,0,0);
}

/*.*/

.mercadopago-button {
    padding: 0 1.7142857142857142em;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 15px !important;
    font-weight: 800 !important;
    line-height: 2.7142857142857144;
    background: #009ee3;
    border-radius: 0.2857142857142857em;
    color: #fff;
    cursor: pointer;
    border: 0;
    width: 120px !important;
}